﻿@import "colors";
@import "variables";
@import "mixins";


//-----------------------------------------------------------------
// Fundamental styles
//-----------------------------------------------------------------

html {
    height: 100%;
	font-size: var(--rem);
	margin: 0;
	padding: 0;
    @include responsive-font(1.75vw, 12.5px, 16.5px, 12.5px);
}

body {
    font-size: 1rem;
    font-family:  var(--font-urbanist);
}

// this may not work 1/6/2022
// changed resize in core service. not fully tested 2/20/2022
div.page {
    min-height: var(--content-height);
    width: 100%;
}

button {
	cursor: pointer;
	pointer-events: auto;
}

label, .label {
    font-family:  var(--font-urbanist);
    color: var(--ib-color-purple);  // lacks indirection
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

p {
    font-family:  var(--font-urbanist);
    font-weight: 500;
    text-align: justify;
	color: var(--ib-color-text);

	&.xxxs { font-size: 0.76rem; }
	&.xxs { font-size: 0.83rem; }
	&.xs { font-size: 0.90rem; }
	&.sm { font-size: 0.97rem; }
	&.md { font-size: 1.0rem; }
	&.lg { font-size: 1.305rem; }
	&.xl { font-size: 1.60rem; }
	&.xxl { font-size: 2.00rem; }
	&.xxxl { font-size: 2.50rem; }
}

p.light {
    color: var(--ib-color-text-light);
}

p.bm-0 { margin-bottom: 0;}

p.center { text-align: center; }

p.hanging-indent, li.hanging-indent {
	padding-left: 1.25rem ;
	text-indent: -1.25rem ;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

i {
    padding-right: .50rem;
    font-size: 1rem;
    .xs { padding-right: 0.325rem; }
    .sm { padding-right: 0.45rem; }
    .md { padding-right: 0.50rem; }
    .lg { padding-right: 0.625rem; }
    .xl { padding-right: 0.75rem; }
    .xxl { padding-right: 1.00rem; }
    .xxxl { padding-right: 1.50rem; }
    .giga { padding-right: 0.00rem; }
}

i.no-padding { padding-right: 0; }

i.xxs {
    font-size: 0.6rem !important;
    margin: 0 0.10rem;
}

i.xs, label.xs {
    font-size: 0.75rem !important;
    margin: 0 0.25rem;
}
i.sm, label.sm {
    font-size: 0.9rem !important;
    margin: 0 0.30rem;
}
i.md, label.md {
    font-size: 1rem !important;
    margin: 0 0.35rem;
}
i.lg, label.lg {
    font-size: 1.25rem !important;
    margin: 0 0.40rem;
}
i.xl, label.xl {
    font-size: 1.5rem !important;
    // margin: 0 0.45rem;
}
i.xxl, label.xxl {
    font-size: 1.75rem !important;
    // margin: 0 5rem;
}
i.xxxl, label.xxxl {
    font-size: 2.5rem !important;
    // margin: 0 0.55rem;
}
i.giga, label.giga {
    font-size: 4rem !important;
    // margin: 0 0.55rem;
}

i.fal.disabled, i.far.disabled, i.fas.disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.35;
}

i.fal.active, i.far.active, i.fas.active {
        pointer-events: none;
        cursor: default;
		border: 1px solid var(--ib-color-gray-500);
}


a.highlight {
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 0.08rem;
    color: var(--ib-color-text);
    text-decoration: none;

    &:hover {
        cursor: pointer;
        color: var(--ib-color-text-light);

        i { color: var(--ib-color-brown) }
    }
}

a.highlight-sm {
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.08rem;
    color: var(--ib-color-text);
    text-decoration: none;

    &:hover {
        color: var(--ib-color-text-light);
    }
}

address {
    text-align: justify;
    // color: var(--ib-color-text);
    font-family:  var(--font-urbanist);

    .xs { font-size: 0.94rem; }
    .sm { font-size: 0.97rem; }
    .md { font-size: 1.0rem; }
    .lg { font-size: 1.305rem; }
    .xl { font-size: 1.60rem; }
    .xxl { font-size: 2.00rem; }
    .xxl { font-size: 2.50rem; }
}

h1 {
	font-family:  var(--font-work-sans);
    color: var(--ib-color-red);
}

h2, h3, h4 {
	font-family:  var(--font-work-sans);
    color: var(--ib-color-marina);
}
h5, h6 {
    font-family:  var(--font-work-sans);
    color: var(--ib-color-cafe-noir);

	&.title {
		margin-left: 1.5rem;
		font-size: 1.10rem;
		font-weight: 500;
		color: var(--ib-color-title);
	}

	span {
		margin-left: 1.5rem;
		font-size: 0.85rem;
		font-weight: 300;
		color: var(--ib-color-text-light);
	}

	span.required {
		font-size: 0.85rem;
		font-weight: 500;
		color: var(--ib-color-text-dark);

	}
}

h1.underlined, h2.underlined, h3.underlined, h4.underlined, h5.underlined, h6.underlined {
	width: 100%;
	border-bottom: 1px solid gray;
	margin: 1.75rem 0 1.35rem 0;
}

span.emphasis {
	font-weight: 700;
}
span.subtle-emphasis {
	font-weight: 600;
}
span.intense-emphasis {
	font-weight: 800;
}

.disabled {
	pointer-events: none;
	cursor: default;
	opacity: 0.35;
}

// ---------------------------------------------------------
// flex
// ---------------------------------------------------------
.flex-row {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

// untested
.align-start { justify-content: flex-start; }
.align-center { justify-content: center; }
.align-end { margin-left: auto; } // ok


// ---------------------------------------------------------
// image
// ---------------------------------------------------------

img.img-width-80 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}

img.img-width-90 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

.img-fit-cover {
	object-fit: cover;
}

.img-fit-contain {
	object-fit: contain;
}

.rounded {
    border-radius: 0.175rem;
}

.rounded-lg {
    border-radius: 0.40rem;
}

.img-rounded-shadow {
    width: 100%;
    height: auto;
    box-shadow: $box-shadow;
    border-radius: 0.40rem;
}

// ---------------------------------------------------------
// input
// ---------------------------------------------------------

// ---------------------------------------------------------
// input
// ---------------------------------------------------------
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}


input {
	background-color: var(--ib-color-gray-200);
	color: var(--ib-color-text);
	border: 1px solid var(--ib-color-gray-300);
	outline: none;
	border-radius: 0.35rem;
}

input:disabled {
	background-color: var(--ib-color-gray-300);
	color: var(--ib-color-text-light);
}

input:focus {
	color: var(--ib-color-text-dark);
	border: 1px solid var(--ib-color-gray-500);
}


// input[type="text"],
// select.form-control {
// background: transparent;
// border: 1px solid var(--ib-color-gray-400);
//   box-shadow: none;
//   border-radius: 0.25rem;
// }

// input[type="text"]:focus,
// select.form-control:focus {
//   box-shadow: none;
// }

.ib.form-group {
    display: flex;
    flex-flow: column nowrap;
}

// ---------------------------------------------------------
// fonts
// ---------------------------------------------------------

.font-title-sans {
	font-family:  var(--font-work-sans);
}

.font-title-sherif {
	font-family:  var(--font-lora);
}

.font-paragraph-sans {
	font-family:  var(--font-urbanist);
}

.font-paragraph-sherif {
	font-family:  var(--font-lora);
}

.font-condensed {
	font-family:  var(--font-urbanist);
}

// ---------------------------------------------------------
// Vertical Spacers
// ---------------------------------------------------------

.tspace-xs {
    margin-top: 0.5rem;
}
.tspace-sm {
    margin-top: 1.0rem;
}
.tspace-md {
    margin-top: 1.5rem;
}
.tspace-lg {
    margin-top: 2.5rem;
}
.tspace-xl {
    margin-top: 3.5rem;
}
.tspace-xxl {
    margin-top: 5rem;
}
.tspace-xxxl {
    margin-top: 8rem;
}

.vspace-xs {
    margin-bottom: 0.5rem;
}
.vspace-sm {
    margin-bottom: 1.0rem;
}
.vspace-md {
    margin-bottom: 1.5rem;
}
.vspace-lg {
    margin-bottom: 2.5rem;
}
.vspace-xl {
    margin-bottom: 3.5rem;
}
.vspace-xxl {
    margin-bottom: 5rem;
}
.vspace-xxxl {
    margin-bottom: 8rem;
}

// -----------------------------------------------------------
// Sizes & Alignment
// -----------------------------------------------------------

.text-center { text-align: center; }
.text-xs {
	font-size: 0.65rem;
	line-height: 0.80rem;
}

.text-sm {
	font-size: 0.80rem;
	line-height: 1rem;
}

.text-md {
	font-size: 0.95rem;
	line-height: 1.15rem;
}

.text-lg {
    font-size: 1.10rem;
	line-height: 1.20rem;
}

.text-xl {
    font-size: 1.35rem;
	line-height: 1.40rem;
}

.text-xxl {
    font-size: 1.75rem;
	line-height: 1.75rem;
}

.text-xxxl {
	font-size: 2.50rem;
	line-height: 2.5rem;
}


.text-offset-xxl-md {
    padding-top: 0.4rem;
}

.text-offset-xl-lg {
    padding-top: 0.08rem;
}

// element width
.w-3 {
    display: inline-block; width: 3rem;
}
.w-5 {
    display: inline-block; width: 5rem;
}
.w-8 {
    display: inline-block; width: 8rem;
}
.w-12 {
    display: inline-block; width: 12rem;
}
.w-16 {
    display: inline-block; width: 16rem;
}
.w-20 {
    display: inline-block; width: 20rem;
}
.w-25 {
    display: inline-block; width: 25rem;
}
.w-30 {
    display: inline-block; width: 30rem;
}
.w-100 {
    display: inline-block; width: 100%;
}

// element max width
.mw-3 {
    max-width: 3rem;
}
.mw-5 {
    max-width: 5rem;
}
.mw-8 {
    max-width: 8rem;
}
.mw-12 {
    max-width: 12rem;
}
.mw-16 {
    max-width: 16rem;
}
.mw-20 {
    max-width: 20rem;
}
.mw-25 {
    max-width: 25rem;
}
.mw-30 {
    max-width: 30rem;
}

//-------------------------------------------------------------------------
// Weight
//-------------------------------------------------------------------------
.weight-lighter {
    font-weight: 300;
}
.weight-light {
    font-weight: 400;
}
.weight-normal {
    font-weight: 500;
}
.weight-heavy {
    font-weight: 600;
}
.weight-heavier {
    font-weight: 700;
}

// ------------------------------------------------------------------------
// UX effects
// ------------------------------------------------------------------------

    .justify {
        text-align: justify;
    }

    .ellipsis {
        overflow-x: hidden;
        overflow-y: no-display;
        text-overflow: ellipsis;
    }

    .clickable {
        cursor: pointer;
        color: var(--ib-color-gray-700);
	}

	.clickable.disabled { color: var(--ib-color-gray-400); }

    .clickable:hover {
        color: var(--ib-color-green) !important;
        & > i {
			color: var(--ib-color-green) !important;
		}
    }

    .shadow:hover {
        box-shadow: 0px 5px 7px -5px #000000, 1px 1px 11px 2px rgba(189,189,189,0);
    }

    .pointer {
        cursor: pointer;
    }

    .pointer-highlight {
        cursor: pointer;

        :hover {
            border-color: #546a79;
            background-color: #f2f2f2;
            -webkit-transition: background-color 350ms linear;
            -moz-transition: background-color 350ms linear;
            -o-transition: background-color 350ms linear;
            transition: background-color 350ms linear;
        }
    }

    .pointer-highlight.disabled {
        cursor: wait;
        pointer-events: none;

        :hover {
            border-color: #d4eaf1;
            background-color: white;
            -webkit-transition: background-color 350ms linear;
            -moz-transition: background-color 350ms linear;
            -o-transition: background-color 350ms linear;
            transition: background-color 350ms linear;
        }
    }

    .pointer-highlight.important {
        background-color: #8eff9a;
    }


// ------------------------------------------------------------------------
// error display
// ------------------------------------------------------------------------

.err-card {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.err-title {
    font-family:  var(--font-lora);
    font-size: 1.40rem;
    color: var(--ib-color-danger);
}
.err-message {
    font-family:  var(--font-urbanist);
    font-size: 1.25rem;
    color: val(--ib-color-gray-800);
}
.err-reason {
    font-family:  var(--font-urbanist);
    font-size: .95rem;
    color: var(--ib-color-gray-600);
}


// ------------------------------------------------------------------------
// views
// ------------------------------------------------------------------------

// hMenu
// ------------------------------------------------------------------------
div.h-menu {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	column-gap: 0.35rem;
	row-gap: 0.35rem;
	margin: 4rem 2rem;

	@media (max-width: 1070px) {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
	}
}


// View Titles

// .card.title {
//     border-top: none;
//     border-top-left-radius: 0;
//     border-top-right-radius: 0;
// }

// .card.menu > div.card-block {

//     display: flex;
//     flex-flow: row nowrap;
//     align-content: center;
//     justify-content: center;

//     > div {

//         display: flex;
//         flex-flow: row wrap;
//         align-content: space-between;
//         justify-content: center;

//         // Extra small devices (portrait phones, less than 576px)
//         @media (max-width: 575.98px) { width: 100%; }
//         // Small devices (landscape phones, 576px and up)
//         @media (min-width: 576px) and (max-width: 767.98px) { width: 94%; }

//         // Medium devices (tablets, 768px and up)
//         @media (min-width: 768px) and (max-width: 991.98px) { width: 84%; }

//         // Large devices (desktops, 992px and up)
//         @media (min-width: 992px) and (max-width: 1199.98px) { width: 74%; }

//         // Extra large devices (large desktops, 1200px and up)
//         @media (min-width: 1200px) { width: 64%; }

//         div.card {
//             display: flex;
//             flex-flow: column nowrap;
//             align-content: space-around;
//             justify-content: center;
//             width: 28%;
//             min-width: 8rem;
//             min-height: 8rem;
//             margin: 0.85rem;

//             > a {
//                 display: flex;
//                 flex-flow: column nowrap;
//                 align-items: center;
//             }
//         }
//     }
// }

// // -------------------------
// // Horizontal Card
// // -------------------------
// .h-card {
//     position: relative;
//     display: flex;
//     flex-flow: row nowrap;
//     word-wrap:  break-word;
//     background: #fff;
//     background-clip: border-box;
//     border: 1px solid rgba(225, 225, 225, 0125);
//     border-radius: 0.25rem;
//     width: 100%;
// }

// .h-card-body {
//     flex: 1 1 auto;
//     padding: 1.25rem;
// }

// .card-img-left {
//     border-top-left-radius: calc(0.25rem - 1px);
//     border-bottom-left-radius: calc(0.25rem - 1px);
// }


// // ------------------------
// // Static Pages
// // ------------------------

// .page-2-3 {
//     display: flex;
//     flex-flow: row nowrap;
//     min-height: 80vh;
//     color: var(--ib-color-text);
//     font-size: 1.10rem;
//     margin: 2rem 1.25rem;

//     .left {
//         flex: 1 1 67%;
//         padding-left: 1rem;
//         padding-right: 1rem;
//         text-align: justify;
//     }

//     .right {
//         flex: 1 1 33%;
//     }

//     @media (max-width: 767.98px) {
//         .left {
//             flex: 1 1 100%;
//             padding-left: 0.75rem;
//             padding-right: 0.75rem;
//         }

//         .right {
//             visibility: hidden;
//         }
//     }

// }


// ------------------------
// Form Styles
//-------------------------

// Form Groups
// .ib.form-group {
//     margin-bottom: 0.25rem;
//     label {
//         color: var(--ib-color-purple);
//         display: flex;
//         flex-flow: row nowrap;
//         justify-content: space-between;
//         align-items: flex-end;
//         margin-bottom: 0.05rem;

//         span.caption {
//             font-size: 1.125rem;
//         }

//         span.error {
//             font-size: 0.8rem;
//             font-weight: 400;
//             color: var(--brand-danger);
//             margin-left: 1.25rem;
//             padding: 0.125rem 0.250rem;
//             border: solid 1px var(--brand-danger);
//             border-radius: 0.25rem;
//             width: auto;
//             background-color: white;
//             i { margin-right: 0.5rem; margin-left: 0.25rem; }
//         }
//     }

//     input {
//         padding: 0.2rem 0.75rem;
//     }
// }

// .ib-form-group1 {
//     display: flex;
//     flex-flow: row nowrap;

//     label {
//         flex: 0 0 8rem;
//         color: var(--ib-color-purple);
//         padding-right: 0.75rem;
//         text-overflow: ellipsis;
//         overflow: hidden;
//         white-space: nowrap;
//         display: flex;
//         align-items: center;
//         justify-content: flex-end;
//         height: 1.65rem;
//     }

//     input, ib-rating {
//         flex: 1 1;
//         height: 1.65rem;
//     }
// }

// // -------------------------------------------------------
// // slide show styling
// // -------------------------------------------------------
// div.show-banner {
// 	display: flex;
// 	flex-flow: column nowrap;
// 	justify-content: space-between;
// 	width: 100%;
// 	padding: 0.25rem 0.5rem;

// 	border: solid 2px  rgba(243, 127, 92, 0.431);
// 	background-color: rgba(245, 229, 224, 0.561);

// 	div.top-line {
// 		display: flex;
// 		margin-top: 0.25rem;
// 		> div.left-line {
// 			margin-left: 1rem;
// 			font-family:  var(--font-urbanist);
// 			font-kerning: auto;
// 			font-stretch: expanded;
// 			font-weight: 700;
// 			color: var(--ib-color-malbec);
// 			font-size: 1.8rem;
// 		}
// 		> div.right-line {
// 			margin-left: auto;
// 			margin-right: 1rem;
// 			font-family:  var(--font-urbanist);
// 			font-size: 1,1rem;
// 			font-weight: 500;
// 			color: var(--ib-color-ink);
// 		}
// 	}

// 	div.middle-line {
// 		> div.left-line {
// 			margin-left: 1rem;
// 			font-family:  var(--font-lora);
// 			font-size: 0.90rem;
// 			font-weight: 400;
// 			color: var(--ib-color-ink);
// 		}
// 	}

// 	div.bottom-line {
// 		margin-bottom: 0.25rem;
// 		> div.right-line {
// 			text-align: right;
// 			margin-right: 1rem;
// 			font-family:  var(--font-lora);
// 			font-size: 0.90rem;
// 			font-weight: 500;
// 			color: var(--ib-color-ink);
// 		}
// 	}

// }

// styles ng-select dropdown
//------------------------------------------------
// .ng-select {
// 	width: 12rem;
//     // border:0px;
//     // min-height: 0px;
// 	// border-radius: 0;
// }

// .ng-select .ng-select-container {
// 	min-height: 1.8rem;
// }

// .ng-select.ng-select-single .ng-select-container {
// 	height: 1.8rem;
// }

// span.ng-option-label {
// 	font-size: 0.875rem;
// 	color: #495057;
// }

// .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
// 	padding: 0.125rem 0.7rem;
// }

// div.ng-input > input {
// 	font-size: 0.875rem;
// 	color: #495057;
// }

// :not([readonly]).ng-valid {
// 	border-right-color: var(--brand-success;);
// 	border-right-width: .5rem;
// }


// Form validation Styles
// --------------------------------------------------------------------------------

// :not([readonly]).ng-pristine.ng-invalid {
//     border-color: var(--brand-warning);
//     border-left-width: .25rem;
// }

// :not([readonly]).ng-touched.ng-invalid {
//     border-color: var(--brand-danger);
//     border-left-width: .25rem;
// }

// :not([readonly]).ng-touched.ng-valid, .ng-dirty.ng-valid {
// 	border-color: var(--brand-success);
// 	border-left-width: .25rem;
// }

// :not([readonly]).ng-dirty.ng-invalid {
//     border-color: var(--brand-danger);
//     border-left-width: .25rem;
// }

// :not([readonly]).ng-pristine.ng-invalid {
//     border-color: var(--brand-warning);
//     border-left-width: .25rem;
// }

// :not([readonly]).ng-touched.ng-invalid {
//     border-color: var(--brand-danger);
//     border-left-width: .25rem;
// }

// :not([readonly]).ng-touched.ng-valid, .ng-dirty.ng-valid {
//     border-color: var(--brand-success);
//     border-left-width: .25rem;
// }

// :not([readonly]).ng-dirty.ng-invalid {
//     border-color: var(--brand-danger);
//     border-left-width: .25rem;
// }




// ---------------------------------------------------------------------
// Notifications
// ----------------------------------------------------------------------

    .notification {
		position: relative;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-content: center;
        min-width: 15rem;
        border: 1px solid #808080;
        border-radius: 0.25rem;
        padding: 0.5rem 1rem;
        background: #31bd1c;
        border-color: #1c720e;
		font-size: 0.85rem;
		z-index: 1000;
        > div {
            align-self: center
        }

        &:hover {
            cursor: pointer;
        }
    }

    .notification.success {
        background: #bff1cc;
        border-color: #c4dbbf;
        color: #1c6e0e;
                i {
            color: var(--ib-color-success);
        }

    }

    .notification.info {
        background: #89cae2;
        border: none;
        color: #1440f1;
        i {
            color: #1440f1 ;
        }

    }

    .notification.warning {
        background: #f6f7ba;
        border-color: var(--brand-warning);
        color: #9a1f1f;
        i {
            color: var(--brand-warning);
        }
    }

    .notification.danger {
        background: rgb(247, 80, 15);
        border-color: #9a1f1f;
        color: antiquewhite;
        i {
            color: yellow;
        }
    }

    // ---------------------------------------------------------------------------
    // kendo overrides
    // ---------------------------------------------------------------------------

    // textbox
    .k-textbox {
		color: var(--ib-color-text);
		outline: none;
		box-shadow: none !important;
		text-shadow: none !important;
	}
    .k-textbox.xl, .k-textbox.lg, .k-textbox.sm {
        height: unset;
    }

    .k-textbox.xl .k-input { font-size: 1.6rem; height: 2.6rem; }
    .k-textbox.lg .k-input { font-size: 1.3rem; height: 2.3rem; }
    .k-textbox.sm .k-input { font-size: 0.85rem; height: 1.05rem; }

	div.k-expander-content { padding: 0; }

	.k-selected {
		--ib-color-selection: var(--ib-color-gray-200);
		--ib-color-selection-background: var(--ib-color-baltic);
		--ib-color-selection-border: var(--ib-color-blue-astronaut);

		color: var(--ib-color-selection);
		background: var(--ib-color-selection-background);
		border: 1px solid var(--ib-color-selection-border);
		border-radius: 0;
	}

	.k-treeview-leaf:hover.k-selected {
		background: rgba(#3A5E75, 0.4) !important;
	}
