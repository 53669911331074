// ------------------------------------------
// Main Style
// -------------------------------------------

@import "./variables";
@import "./common";

pub-root {
    display: flex;
    flex-direction: column;
}

main {
	min-height: var(--content-height);
    overflow: visible;
    display: flex;
	flex-flow: column nowrap;
}

// Bootstrap overrides

// textarea,
// textarea.form-control,
// input.form-control,
// input[type=text],
// input[type=password],
// input[type=email],
// input[type=number],
// [type=text].form-control,
// [type=password].form-control,
// [type=email].form-control,
// [type=tel].form-control,
// [contenteditable].form-control {
//   border-color: rgb(58, 77, 245);
// }

// textarea:focus,
// textarea.form-control:focus,
// input.form-control:focus,
// input[type=text]:focus,
// input[type=password]:focus,
// input[type=email]:focus,
// input[type=number]:focus,
// [type=text].form-control:focus,
// [type=password].form-control:focus,
// [type=email].form-control:focus,
// [type=tel].form-control:focus,
// [contenteditable].form-control:focus {
//   box-shadow: inset 0 -1px 0 #ddd;
//   // border-color: red;
// }



// kendo-angular-buttons
@import "@progress/kendo-theme-bootstrap/scss/button/_index.scss";
// @import "~@progress/kendo-theme-bootstrap/scss/chip/_index.scss";
// @import "~@progress/kendo-theme-bootstrap/scss/fab/_index.scss";

// kendo-angular-charts
// @import "~@progress/kendo-theme-bootstrap/scss/dataviz/_index.scss";

// kendo-angular-conversational-ui
// @import "~@progress/kendo-theme-bootstrap/scss/chat/_index.scss";

// kendo-angular-dateinputs
// @import "~@progress/kendo-theme-bootstrap/scss/datetime/_index.scss";

// kendo-angular-dialog
// @import "~@progress/kendo-theme-bootstrap/scss/dialog/_index.scss";
// @import "~@progress/kendo-theme-bootstrap/scss/window/_index.scss";

// kendo-angular-dropdowns
@import "@progress/kendo-theme-bootstrap/scss/autocomplete/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/combobox/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/dropdownlist/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/dropdowntree/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/multiselect/_index.scss";

// kendo-angular-gauges
// @import "~@progress/kendo-theme-bootstrap/scss/dataviz/_index.scss";

// kendo-angular-grid
// @import "~@progress/kendo-theme-bootstrap/scss/grid/_index.scss";

// kendo-angular-editor
// @import "~@progress/kendo-theme-bootstrap/scss/editor/_index.scss";

// kendo-angular-icons
// @import "~@progress/kendo-theme-bootstrap/scss/icons/_index.scss";

// kendo-angular-indicators
// @import "~@progress/kendo-theme-bootstrap/scss/badge/_index.scss";
// @import "~@progress/kendo-theme-bootstrap/scss/loader/_index.scss";

// kendo-angular-inputs
@import "@progress/kendo-theme-bootstrap/scss/checkbox/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/colorpicker/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/forms/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/input/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/maskedtextbox/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/numerictextbox/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/radio/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/slider/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/switch/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/textarea/_index.scss";
// @import "~@progress/kendo-theme-bootstrap/scss/textbox/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/validator/_index.scss";



// kendo-angular-layout
@import "@progress/kendo-theme-bootstrap/scss/avatar/_index.scss";
// @import "~@progress/kendo-theme-bootstrap/scss/card/_index.scss";
// @import "~@progress/kendo-theme-bootstrap/scss/drawer/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/expansion-panel/_index.scss";
// @import "~@progress/kendo-theme-bootstrap/scss/panelbar/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/splitter/_index.scss";
// @import "~@progress/kendo-theme-bootstrap/scss/stepper/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/tabstrip/_index.scss";
// @import "~@progress/kendo-theme-bootstrap/scss/tilelayout/_index.scss";

// kendo-angular-menu
// @import "~@progress/kendo-theme-bootstrap/scss/menu/_index.scss";

// kendo-angular-navigation
// @import "~@progress/kendo-theme-bootstrap/scss/appbar/_index.scss";
// @import "~@progress/kendo-theme-bootstrap/scss/breadcrumb/_index.scss";
// @import "~@progress/kendo-theme-bootstrap/scss/bottom-navigation/_index.scss";

// kendo-angular-notification
// @import "~@progress/kendo-theme-bootstrap/scss/notification/_index.scss";

// kendo-angular-pager
// @import "~@progress/kendo-theme-bootstrap/scss/pager/_index.scss";

// kendo-angular-popup
@import "@progress/kendo-theme-bootstrap/scss/popup/_index.scss";

// kendo-angular-progressbar
// @import "~@progress/kendo-theme-bootstrap/scss/progressbar/_index.scss";

// kendo-angular-ripple
// @import "~@progress/kendo-theme-bootstrap/scss/ripple/_index.scss";

// kendo-angular-scrollview
// @import "~@progress/kendo-theme-bootstrap/scss/scrollview/_index.scss";

// kendo-angular-toolbar
// @import "~@progress/kendo-theme-bootstrap/scss/toolbar/_index.scss";

// kendo-angular-tooltip
@import "@progress/kendo-theme-bootstrap/scss/tooltip/_index.scss";

// kendo-angular-treelist
// @import "~@progress/kendo-theme-bootstrap/scss/treelist/_index.scss";

// kendo-angular-treeview
@import "@progress/kendo-theme-bootstrap/scss/treeview/_index.scss";

// kendo-angular-upload
// @import "~@progress/kendo-theme-bootstrap/scss/dropzone/_index.scss";
@import "@progress/kendo-theme-bootstrap/scss/upload/_index.scss";
