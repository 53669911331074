
@use 'sass:math';

//-----------------------------------------------------------------
// Mixins
//-----------------------------------------------------------------
///
/// Viewport sized typography with minimum and maximum values
///
/// @author Eduardo Boucas (@EduardoBoucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback),
///                 minimum of 35px and maximum of 150px
///  @include responsive-font(5vw, 35px, 150px, 50px);
///
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
    $responsive-unitless: math.div($responsive, ($responsive - $responsive + 1));
    $dimension: if(unit($responsive) == 'vh', 'height', 'width');
    $min-breakpoint: math.div($min, $responsive-unitless) * 100;

    @media (max-#{$dimension}: #{$min-breakpoint}) {
      font-size: $min;
    }

    @if $max {
      $max-breakpoint: math.div($max, $responsive-unitless) * 100;

      @media (min-#{$dimension}: #{$max-breakpoint}) {
        font-size: $max;
      }
    }

    @if $fallback {
      font-size: $fallback;
    }

    font-size: $responsive;
}

@mixin for-size($size) {
  @if $size == xs {
    @media (max-width: 599px) { @content; }
  } @else if $size == sm {
    @media (min-width: 600px) { @content; }
  } @else if $size == md {
    @media (min-width: 900px) { @content; }
  } @else if $size == lg {
    @media (min-width: 1200px) { @content; }
  } @else if $size == xl {
    @media (min-width: 1800px) { @content; }
  }
}



