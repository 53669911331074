//=================================
// Variables
//=================================

$rem: 12.5px;

$media-min-sm: 576px;
$media-min-md: 768px;
$media-min-lg: 992px;
$media-min-xl: 1280px;
$media-min-hd: 1920px;
$media-min-4k: 4000px;

$media-max-xs: 575px;
$media-max-sm: 767px;
$media-max-md: 991px;
$media-max-lg: 1279px;
$media-max-xl: 1919px;
$media-max-dd: 3999px;



$content-padding: 2.5rem 3.5rem;

$box-shadow: 0 .5rem 1rem rgba(#000, .55) !default;
$box-shadow-sm: 0.15rem .15rem .3rem rgba(#000, .55);
$gradient: linear-gradient(to top, var(--ib-color-gray-200, ib-color-gray-400));
$gradient-g: linear-gradient(0deg, rgba(196,196,196,1) 0%, rgba(231,231,231,1) 51%, rgba(226,226,226,1) 100%);

:root {

	--rem: 12.5px;					// default pixels per rem
	--client-width: 0;				// default - automatically set in core service
	--dynamic-margin: 0;    		// default - automatically set in core service
	--client-height: 0px;				// default - automatically set in core service
	--header-height: 0px;  			// default - automatically set in core service
	--title-height: 0px;			// default - automatically set in core service

	--main-height: calc(var(--client-height) - var(--header-height));
	--content-height: calc(var(--main-height) - var(--title-height));


	--font-urbanist: Urbanist, Arial, Helvetica, sans-serif;
	--font-railwayL: Railway, Urbanist, Arial, helvetica, sans-serif;
	--font-lora: Lora, "Times New Roman", Times, serif;;
	--font-work-sans: Work Sans, Arial, Helvetica, sans-serif;


}

