//------------------------------------------------------
// Colors
//------------------------------------------------------


:root {

    --main: #c1272d;
    --ib-color-iBreeder: var(--main);
    --ib-color-iBreeder-complement: var(--ib-color-web-orange);
    --ib-color-breeder: var(--ib-color-mule-fawn);
	--ib-color-breeder-space: var(--ib-color-cerulus);
    --ib-color-breed: var(--ib-color-antique-brass);
    --ib-color-litter: var(--ib-color-parl);
    --ib-color-dog: var(--ib-color-macaroni-cheese);
	--ib-color-dog-owner: var(--ib-color-eden);

    // raw colors

	--ib-color-alabaster: #dfdfd2;
    --ib-color-anakiwa: #99DDFF;
    --ib-color-antique-brass: #C88A65;
    --ib-color-apricot: #EE7879;
    --ib-color-aqua-forest: #5DAA68;
    --ib-color-axolotl: #4E6649;
    --ib-color-baltic: #3A5E75;
    --ib-color-baby-powder: #fffffb;
    --ib-color-black-sand: #3F3931;
    --ib-color-blue: #2a6496;
    --ib-color-blue-astronaut: #01345B;
	--ib-color-blue-cerulus: #438EE9;
    --ib-color-blue-dodger: #487AFA;
    --ib-color-blue-eastern: #1E9AB0;
    --ib-color-blue-fun: #16519F;
    --ib-color-blue-light: #ccdef6;
	--ib-color-blue-pastel: #96B2EB;
    --ib-color-blue-picton: #4B9FE1;
    --ib-color-blue-smalt: #004687;
    --ib-color-blue-tory: #385d8e;
    --ib-color-blue-turquoise: #63BCE5;
    --ib-color-blue-zodiac: #0F2557;
    --ib-color-blush: #C1436D;
    --ib-color-bright-sun: #FDD935;
    --ib-color-brown: #b81d18;
    --ib-color-brown-dark: #7d0b0b;
    --ib-color-brown-livid: #4A2C40;
    --ib-color-bunker: #0D1117;
    --ib-color-cactus: #5C6E58;
    --ib-color-cafe-noir: #32485c;
    --ib-color-cashmere: #cbc5c1;
    --ib-color-chambray: #354E8C;
    --ib-color-champagne: #FAF1CF;
    --ib-color-charcoal: #32485c;
    --ib-color-chartreuse: #DFFF00;
    --ib-color-claret: #9B1B3B;
    --ib-color-cloud-burst: #1F3044;
    --ib-color-cod-gray: #070707;
    --ib-color-congress-blue: #02478E;
    --ib-color-congo-brown: #593737;
    --ib-color-cornsilk: #FFF8DC;
    --ib-color-crimson: #E12B38;
    --ib-color-danube: #6688CC;
    --ib-color-dark-gray: #202020;
    --ib-color-dark-green: #0b4d03;
    --ib-color-denim: #1181B2;
    --ib-color-dollar: #85bb65;
    --ib-color-dove-gray: #696868;
    --ib-color-ecstasy: #FB8122;
    --ib-color-english-holly: #022D15;
    --ib-color-eden: #0B4141;
    --ib-color-envy: #8AA899;
    --ib-color-foliage-green: #506054;
    --ib-color-fjord: #425664;
    --ib-color-goblin: #438945;
    --ib-color-gold: goldenrod;
    --ib-color-gray-steel: #1D1D2C;
    --ib-color-green: #11a414;
    --ib-color-green-bright: #91ed60;
    --ib-color-green-dark: #044a04;
    --ib-color-green-darker: #022a09;
    --ib-color-green-light: #b1f1a1;
	--ib-color-green-pale: #e8f7e5;
    --ib-color-green-chateau: #3EB650;
    --ib-color-green-laurel: #026607;
    --ib-color-gumbo: #7DA2A9;
    --ib-color-ink: #32485c;
	--ib-color-ivory: #FFFFF1;
    --ib-color-java: #23C0AD;
    --ib-color-kilarney: #3F6844;
    --ib-color-la-palma: #368716;
    --ib-color-lavender: #B57EDC;
    --ib-color-macaroni-cheese: #FFB97B;
    --ib-color-malbec: #5B1341;
    --ib-color-marina: #135589;
    --ib-color-marine-green: #6D7973;
    --ib-color-merino: #F7F4E9;
    --ib-color-mine-shaft: #323232;
    --ib-color-minsk: #3F307F;
    --ib-color-monza: #C7031E;
    --ib-color-mountain-meadow: #1AB385;
    --ib-color-mulberry-wood: hsl(336, 85%, 21%);
    --ib-color-mule-fawn: #8c472F;
    --ib-color-navy: navy;
    --ib-color-old-brick: #98261c;
    --ib-color-orange: #f7941e;
    --ib-color-orange-tulip: #EBA63F;
    --ib-color-pale: #EEFBF5;
    --ib-color-pampas: #F6F4F2;
    --ib-color-parl: #AD4328;
    --ib-color-pastel-green: #77DD77;
    --ib-color-pink: #FFC0CB;
    --ib-color-purple: purple;
    --ib-color-purple-affair: #7c5a8f;
    --ib-color-purple-light: #ba86d6;
	--ib-color-purple-gender: #9f3be3;
    --ib-color-rajah: #FAB162;
    --ib-color-raw-siena: #D27D46;
    --ib-color-red: red;
    --ib-color-red-coral: #FF4653;
    --ib-color-red-damask: #D97441;
    --ib-color-red-guardsman:#BA0101;
    --ib-color-red-pale: #fc7f7f;
    --ib-color-red-ribbon: #ED0A3F;
    --ib-color-rhino: #2A3166;
    --ib-color-ripe-lemon: #F4D81C;
    --ib-color-saddle: #4C3024;
    --ib-color-san-felix: #0B6207;
    --ib-color-shark: #292930;
    --ib-color-shingle-fawn: #6B4E31;
    --ib-color-shuttle-gray: #646C79;
    --ib-color-skeptic: #CAE7DF;
	--ib-color-sky: #6EC5E9;
    --ib-color-slate: #6f7787;
    --ib-color-spindle: #B4D0E7;
    --ib-color-steel-blue: #4682B4;
    --ib-color-st-topaz: #28559A;
    --ib-color-sulu: #C1F07C;
    --ib-color-sunglow: #FCC133;
    --ib-color-sushi: #87AB39;
    --ib-color-teal: #08708a;
    --ib-color-teal-light: #2b90b7;
    --ib-color-trendy-pink1: #8C6495;
    --ib-color-tulip-tree: #EAB33B;
    --ib-color-turquoise: #3FD2C7;
    --ib-color-ultramarine: #008C72;
    --ib-color-venice: #6CBF84;
    --ib-color-violet-livid: #7D3780;
    --ib-color-we-peep: #F6DADF;
    --ib-color-web-orange: #FFA500;
    --ib-color-wild-strawberry: #FF3399;
    --ib-color-yellow: #ffd800;
    --ib-color-yellow-pale: #fafbdf;

    // stock colors

    --brand-primary: #428bca;
    --brand-success: #5cb85c;
    --brand-warning: #f0ad4e;
    --brand-danger: #d9534f;
    --brand-info: #5bc0de;

    --ib-color-black: #000;
    --ib-color-complete: var(--brand-success);
    --ib-color-error: var(--brand-danger);
    --ib-color-disabled: var(--ib-color-gray-300);

	--ib-color-gray-50: #fbfbfb;
    --ib-color-gray-100: #f8f9fa;
    --ib-color-gray-200: #e9ecef;
    --ib-color-gray-300: #dee2e6;
    --ib-color-gray-400: #ced4da;
    --ib-color-gray-500: #adb5bd;
    --ib-color-gray-600: #6c757d;
    --ib-color-gray-700: #495057;
    --ib-color-gray-800: #343a40;
    --ib-color-gray-900: #212529;
    --ib-color-white: #fff ;

    // social colors

    --ib-color-web: var(--main);
    --ib-color-facebook: #3b5998;
    --ib-color-twitter: #1DA1F2;
    --ib-color-google: #4285f4;
    --ib-color-instagram: rgb(108, 87, 122);
    --ib-color-snapchat: #FFFC00;

    // element & state colors

    --ib-color-empty: var(--ib-color-gray-300);
    --ib-color-partial: var(--ib-color-sulu);
    --ib-color-complete: var(--brand-success);
    --ib-color-error: var(--brand-danger);
    --ib-color-waiting: var(--ib-color-gray-500);
    --ib-color-published: var(--ib-color-san-felix);
	--ib-color-required: var(--ib-color-parl);

	// background colors
	--ib-color-background-primary: var(--ib-color-white); //
	--ib-color-background-secondary: var(--ib-color-ivory); //
    --ib-color-background-pale: var(--ib-color-pale);
    --ib-color-background-gray: #e6e6e6;
    --ib-color-background-soft: #faf5f5fa;

	// foreground colors

    // --ib-color-header-background: #232f3e;
    // --ib-color-header-color: #f1f1f1;
    // --ib-color-header-status: var(--ib-color-pampas);

	// borders
    --ib-color-border: var(--ib-color-alabaster);
    --ib-color-card-border: $card-border-color;

    --ib-color-page-header-bgd1:Rgba(238, 242, 243, 0.5);
    --ib-color-page-header-bgd2: Rgba(142, 158, 243, 0.5);
    --ib-color-page-header-color: var(--ib-color-brown-dark);
    --ib-color-page-header-color-highlight: var(--ib-color-mulberry-wood);

    --ib-color-footer-background: var(--ib-color-black-sand);
    --ib-color-footer-link: var(--ib-color-axolotl);
    // --ib-color-footer-link: var(--ib-color-blue-smalt);
    --ib-color-footer-link-hover: var(--ib-color-crimson);
    --ib-color-footer-copyright: var(--ib-color-aqua-forest);
    --ib-color-footer-copyright-hover: var(--ib-color-green-bright);
    --ib-color-footer-menu: var(--ib-color-red-pale);
    --ib-color-footer-menu-hover: var(--ib-color-cornsilk);

    --ib-color-menu-icon: var(--ib-color-navy);
    --ib-color-menu-background: #fff6ed;
    --ib-color-menu-caption: var(--ib-color-shuttle-gray);
    --ib-color-menu-highlight: #c7d0d5;
    --ib-color-menu-intense: #ec583a;
    --ib-color-menu-active: #e1e1e1;
    --ib-color-menu-hover: #f2f2f2;
    --ib-color-menu-active-text: var(--ib-color-gray-200);
    --ib-color-menu-separator: var(--ib-color-black-sand);

    --ib-color-button: #8aa6b9;
    --ib-color-button-bg: #c7d0d5;
	--ib-color-button-icon: var(--ib-color-bright-sun);

    --ib-color-action-button-bg: var(--ib-color-aqua-forest);

	--ib-color-icon: var(--ib-color-shark);
	--ib-color-icon-light: var(--ib-color-gray-600);
    // --ib-color-icon-blue: var(--ib-color-blue-smalt);
    // --ib-color-icon-blue-light: var(--ib-color-blue-light);
    // --ib-color-icon-orange: var(--ib-color-orange);
	// --ib-color-icon-pending: var(--ib-color-ripe-lemon);
	--ib-color-image-caption: var(--ib-color-blue-light);
	--ib-color-image-caption-dark: var(--ib-color-charcoal);

    --ib-color-text: var(--ib-color-mine-shaft);
    --ib-color-text-light: var(--ib-color-dove-gray);
    --ib-color-text-dark: var(--ib-color-cod-gray);
	--ib-color-text-highlight: var(--ib-color-mulberry-wood);

	--ib-color-input: var(--ib-color-cloud-burst);

    --ib-color-title: var(--ib-color-marina);
	--ib-color-title-icon: var(--ib-color-blue-smalt);
    --ib-color-subtitle: var(--ib-color-teal);
	--ib-color-star: var(--ib-color-green-laurel);

    --ib-color-label: var(--ib-color-purple);
    --ib-color-heading:	var(--ib-color-dove-gray);
    // --ib-color-component-active: #819191;
    // --ib-color-component-hover: #b1bcc2;
    --ib-color-card-background: var(--ib-color-white);
    --ib-color-card-header-background: rgba(0,0,0,0.03);

	--ib-color-notification-light-green: var(--ib-color-skeptic);
	--ib-color-notification-dark-green: var(--ib-color-english-holly);

	--ib-color-search-background: var(--ib-color-baby-powder);
	--ib-color-search-foreground: var(--ib-color-blue-astronaut);
	--ib-color-search-background-focus: var(--ib-color-corn-silk);
	--ib-color-search-foreground-focus: var(--ib-color-cod-gray);
	--ib-color-search-button: var(--ib-color-iBreeder-complement);
	--ib-color-search-button-empty: var(--ib-color-gray-500);
	--ib-color-search-button-title: var(--ib-color-skeptic);
	--ib-color-search-button-caption: var(--ib-color-english-holly);

	--ib-color-selected: var(--ib-color-blue-fun);
	--ib-color-selection: var(--ib-color-gray-200);
	--ib-color-selection-background: var(--ib-color-baltic);
	--ib-color-selection-border: var(--ib-color-blue-astronaut);

    // named colors

    --ib-color-account: var(--ib-color-blue-tory);
    --ib-color-accounting: var(--ib-color-dollar);
    --ib-color-breeding: var(--ib-color-red-damask);
	--ib-color-calendar: var(--ib-color-anakiwa);
    --ib-color-check: var(--brand-success);
	--ib-color-check-list: var(--ib-color-envy);
	--ib-color-client: var(--ib-color-ultramarine);
	--ib-color-cloud: var(--ib-color-sky);
	--ib-color-credit-card: var(--ib-color-charcoal);
    --ib-color-commitment: var(--ib-color-steel-blue);
    --ib-color-contact-us: var(--ib-color-danube);
    --ib-color-design: var(--ib-color-sunglow);
    --ib-color-data: var(--ib-color-green-dark);
    --ib-color-document: var(--ib-color-blue-smalt);
    --ib-color-edit: var(--ib-color-gray-700);
    --ib-color-email: var(--ib-color-marina);
    --ib-color-empty: var(--ib-color-gray-300);
    --ib-color-favorites: var(--ib-color-crimson);
	--ib-color-false: var(--ib-color-green-dark);
    --ib-color-female: var(--ib-color-pink);
	--ib-color-female-male: var(--ib-color-purple-gender)
	--ib-color-gender: var(--ib-color-brown);
	--ib-color-genderless: var(--ib-color-sunglow);
    --ib-color-get-started: var(--ib-color-red);
    --ib-color-globe: var(--ib-color-blue-turquoise);
    --ib-color-guide: var(--ib-color-kilarney);
	--ib-color-health: var(--ib-color-red-ribbon);
    --ib-color-help: var(--ib-color-pastel-green);
    --ib-color-home: var(--ib-color-iBreeder);
    --ib-icon-id: var(--ib-color-cafe-noir);
    --ib-color-image: var(--ib-color-green);
    --ib-color-impersonate: var(--ib-color-violet-livid);
    --ib-color-info: var(--ib-color-navy);
	--ib-color-key: var(--ib-color-rhino);
    --ib-color-list: var(--ib-color-charcoal);
    --ib-color-location: var(--ib-color-blue-tory);
	--ib-color-lock: var(--ib-color-charcoal);
	--ib-color-media: var(--ib-color-danube);
    --ib-color-magic: var(--ib-color-red-damask);
    --ib-color-main: var(--ib-color-st-topaz);
    --ib-color-male: var(--ib-color-congress-blue);
    --ib-color-message: var(--ib-color-green-bright);
    --ib-color-minus: var(--ib-color-red-guardsman);
	--ib-color-navigate: var(--ib-color-blue-zodiac);
	--ib-color-note: var(--ib-color-raw-siena);
    --ib-color-notifications: var(--ib-color-blush);
    --ib-color-onboarding: var(--ib-color-green-dark);
    --ib-color-page: var(--ib-color-fjord);
    --ib-color-partial: var(--ib-color-green-light);
    --ib-color-phone: var(--ib-color-blue-astronaut);
    --ib-color-preview: var(--ib-color-blue-fun);
    --ib-color-print: var(--ib-color-gray-800);
    --ib-color-plus: var(--ib-color-green-laurel);
	--ib-color-privacy: var(--ib-color-blue-dodger);
    --ib-color-profile: var(--ib-color-kilarney);
	--ib-color-prospect: var(--ib-color-san-felix);
	--ib-color-public: var(--ib-color-blue-eastern);
	--ib-color-publish: var(--ib-color-blue);
    --ib-color-question: var(--ib-color-purple);
	--ib-color-reference: var(--ib-color-wild-strawberry);
    --ib-color-register: var(--ib-color-java);
	--ib-color-reviews: var(--ib-color-orange);
    --ib-color-save: var(--ib-color-blue-smalt);
	--ib-color-scroll: var(--ib-color-purple);
	--ib-color-settings: var(--ib-color-charcoal);
	--ib-color-service: var(--ib-color-mountain-meadow);
	--ib-color-sort: var(--ib-color-blue);
    --ib-color-support: var(--ib-color-orange);
    --ib-color-target: var(--ib-color-old-brick);
    --ib-color-technology: var(--ib-color-chambray);
    --ib-color-terms: var(--ib-color-saddle);
    --ib-color-tracking: var(--ib-color-raw-siena);
    --ib-color-trust: var(--ib-color-steel-blue);
    --ib-color-user: var(--ib-color-teal-light);
    --ib-color-user-owner: var(--ib-color-blue-zodiac);
    --ib-color-user-admin: var(--ib-color-blue-smalt);
    --ib-color-user-general: var(--ib-color-teal);
	--ib-color-video: var(--ib-color-charcoal);
    --ib-color-waiting: var(--ib-color-gray-500);
    --ib-color-webinar: var(--ib-color-ecstasy);
	--ib-color-visit: var(--ib-color-cactus);
	--ib-color-wifi: var(--ib-color-st-topaz);

}

//----------------------------------------------
// Colors class names
//----------------------------------------------

.ib-color-iBreeder { color: var(--ib-color-iBreeder); }
.ib-color-iBreeder-complement{ color: var(--ib-color-iBreeder-complement); }
.ib-color-breeder { color: var(--ib-color-breeder); }
.ib-color-breeder-space { color: var(--ib-color-breeder-space); }
.ib-color-breed { color: var(--ib-color-breed); }
.ib-color-litter {color: var(--ib-color-litter); }
.ib-color-dog { color: var(--ib-color-dog); }
.ib-color-dog-owner {color: var(--ib-color-dog-owner); }

// raw colors

.ib-color-red { color: var(--ib-color-red); }

// // stock colors

.ib-color-success { color: var(--brand-success); }
.ib-color-info { color: var(--brand-primary); }
.ib-color-warning { color: var(--brand-warning); }
.ib-color-danger {  color: var(--brand-danger); }

.ib-color-disabled { color: var(--ib-color-disabled); }


.ib-color-white { color: var(--ib-color-white); }
.ib-color-gray-100 { color: var(--ib-color-gray-100); }
.ib-color-gray-200 { color: var(--ib-color-gray-200); }
.ib-color-gray-300 { color: Var(--ib-color-gray-300); }
.ib-color-gray-400 { color: var(--ib-color-gray-400); }
.ib-color-gray-500 { color: var(--ib-color-gray-500); }
.ib-color-gray-600 { color: var(--ib-color-gray-600); }
.ib-color-gray-700 { color: var(--ib-color-gray-700); }
.ib-color-gray-800 { color: var(--ib-color-gray-800); }
.ib-color-gray-900 { color: var(--ib-color-gray-900); }
.ib-color-black { color: var(--ib-color-black); }

// // social colors

.ib-color-web  { color: var(--ib-color-web); }
.ib-color-facebook { color: var(--ib-color-facebook); }
.ib-color-twitter { color: var(--ib-color-twitter); }
.ib-color-google { color: var(--ib-color-google); }
.ib-color-instagram { color: var(--ib-color-instagram); }
.ib-color-snapchat { color: var(--ib-color-snapchat); }

// element && state colors

.ib-color-empty  { color: var(--ib-color-empty); }
.ib-color-partial  { color: var(--ib-color-partial); }
.ib-color-complete  { color: var(--ib-color-complete); }
.ib-color-error  { color: var(--ib-color-error); }
.ib-color-waiting  { color: var(--ib-color-waiting); }
.color-published { color: var(--ib-color-published); }
.ib-color-required { color: var(--ib-color-parl); }

.ib-color-icon { color: var(--ib-color-icon); }
.ib-color-icon-blue { color: var(--ib-color-blue-smalt); };
.ib-color-icon-blue-light { color: var(--ib-color-blue-light); }
.ib-color-icon-orange { color: var(--ib-color-orange); };
.ib-color-icon-selected { color: var(--ib-color-selected); }

.ib-color-text { color: var(--ib-color-text); }
.ib-color-text-light { color: var(--ib-color-text-light); }
.ib-color-text-dark { color: var(--ib-color-text-dark); }
.ib-color-text-highlight { color: var(--ib-color-text-highlight); }

.ib-color-input {color: var(--ib-color-cloud-burst); }

.ib-color-title { color: var(--ib-color-title); }
.ib-color-title-icon { color: var(--ib-color-title-icon) }

.ib-color-plus { color: var(--ib-color-plus); }
.ib-color-minus { color: v(--ib-color-minus);}

.ib-color-button-bg { color: var(--ib-color-button-bg); }
.ib-color-button { color: var(--ib-color-button); }
.ib-color-button-icon { color: var(--ib-color-button-icon); }


.ib-color-icon { color: var(--ib-color-icon); }
.ib-color-icon-title { color: var(--ib-color-page-header-color-highlight); }

.ib-color-background { color: var(--ib-color-background); }
.ib-color-background-gray { color: var(--ib-color-background-gray); }
.ib-color-background-pale { color: var(--ib-color-background-pale); }

//check
.ib-color-card-border{ color: var(--ib-color-card-border); }


.ib-color-menu-icon { color: var(--ib-color-menu-icon); }
.ib-color-menu-background { color: var(--ib-color-menu-background); }
.ib-color-menu-caption { color: var(--ib-color-menu-caption); }
.ib-color-menu-highlight { color: var(--ib-color-menu-highlight); }
.ib-color-menu-intense { color: var(--ib-color-menu-intense); }
.ib-color-menu-active { color: var(--ib-color-menu-active); }
.ib-color-menu-hover { color: var(--ib-color-menu-hover); }
.ib-color-menu-active-text { color: var(--ib-color-active-text); }

.ib-color-notification-light-green { color: var(--ib-color-skeptic); }


//check

.ib-color-search { color: var(--ib-color-brown); }
.ib-color-search-button { color: var(--ib-color-search-button); }
.ib-color-search-button-empty { color: var(--ib-color-search-button-empty); }

.ib-color-selected { color: var(--ib-color-selected); }


.ib-color-star { color: var(--ib-color-green-laurel); }

// ------------------------------------------

.ib-color-account { color: var(--ib-color-account); }
.ib-color-accounting { color: var(--ib-color-dollar); }
.ib-color-breeding { color: var(--ib-color-breeding); }
.ib-color-calendar { color: var(--ib-color-calendar); }
.ib-color-check { color: var(--ib-color-check); }
.ib-color-check-list { color: var(--ib-color-check-list); }
.ib-color-client { color: var(--ib-color-client); }
.ib-color-cloud { color: var(--ib-color-cloud); }
.ib-color-commitment { color: var(--ib-color-commitment); }
.ib-color-contact-us { color: var(--ib-color-iBreeder-complement); }
.ib-color-credit-card{ color: var(--ib-color-credit-card); }
.ib-color-data { color: var(--ib-color-green-dark); }
.ib-color-design { color: var(--ib-color-design); }
.ib-color-document { color: var(--ib-color-document); }
.ib-color-dollar { color: var(--ib-color-dollar); }
.ib-color-edit { color: var(--ib-color-edit); }
.ib-color-false { color: var(--ib-color-green-dark) }
.ib-color-email { color: var(--ib-color-email); }
.ib-color-favorites { color: var(--ib-color-favorites); }
.ib-color-female { color: var(--ib-color-female); }
.ib-color-female-male { color: var(--ib-color-female-male); }
.ib-color-gender { color: var(--ib-color-gender); }
.ib-color-genderless { color: var(--ib-color-sunglow); }
.ib-color-get-started { color: var(ib-color-get-started); }
.ib-color-globe { color: var(--ib-color-globe); }
.ib-color-guide { color: var(--ib-color-guide); }
.ib-color-health { color: var(--ib-color-health); }
.ib-color-help { color: var(--ib-color-help); }
.ib-color-home { color: var(--ib-color-home); }
.ib-icon-id { color: var(--ib-color-icon-id); }
.ib-icon-info { color: var(--ib-color-info); }
.ib-color-image { color: var(--ib-color-image); }
.ib-color-impersonate { color: var(--ib-color-impersonate); }
.ib-color-key { color:  var(--ib-color-key); }
.ib-color-list { color: var(--ib-color-list) };
.ib-color-location  { color: var(--ib-color-location); }
.ib-color-lock { color: var(--ib-color-lock); }
.ib-color-magic { color: var(--ib-color-magic); }
.ib-color-male { color: var(--ib-color-congress-blue); }
.ib-color-media { color: var(--ib-color-media); }
.ib-color-message { color: var(--ib-color-message); }
.ib-color-navigate { color: var(--ib-color-navigate); }
.ib-color-note { color: var(--ib-color-note); }
.ib-color-notifications { color: var(--ib-color-notifications); }
.ib-color-onboarding { color: var(--ib-color-onboarding); }
.ib-color-page { color: var(--ib-color-page); }
.ib-color-phone { color: var(--ib-color-phone); }
.ib-color-preview { color: var(--ib-color-preview); }
.ib-color-print { color: var(--ib-color-print); }
.ib-color-privacy { color: var(--ib-color-privacy); }
.ib-color-profile { color: var(--ib-color-profile); }
.ib-color-prospect { color: var(--ib-color-prospect); }
.ib-color-provider { color: var(--ib-color-provider); }
.ib-color-public { color: var(--ib-color-public); }
.ib-color-publish { color: var(--ib-color-publish); }
.ib-color-question { color: var(--ib-color-question); }
.ib-color-reference { color: var(--ib-color-reference); }
.ib-color-register { color: var(--ib-color-register); }
.ib-color-reviews { color: var(--ib-color-orange); }
.ib-color-save { color: var(--ib-color-save); }
.ib-color-scroll{ color: var(--ib-color-purple); }
.ib-color-service { color: var(--ib-color-service); }
.ib-color-settings { color: var(--ib-color-charcoal); }
.ib-color-sort { color: var(--ib-color-sort); }
.ib-color-support { color: var(--ib-color-support); }
.ib-color-target { color: var(--ib-color-target); }
.ib-color-technology { color:  var(--ib-color-technology); }
.ib-color-terms { color: var(--ib-color-terms); }
.ib-color-test { color: var(--ib-color-test); }
.ib-color-tracking { color: var(--ib-color-tracking); }
.ib-color-trust { color: var(--ib-color-trust); }
.ib-color-user { color: var(--ib-color-user); }
.ib-color-user-owner { color: var(--ib-color-user-owner); }
.ib-color-user-admin { color: var(--ib-color-user-admin); }
.ib-color-user-general { color: var(--ib-color-user-general); }
.ib-color-video { color:  var(--ib-color-charcoal); }
.ib-color-visit { color: var(--ib-color-visit); }
.ib-color-webinar { color: var(--ib-color-webinar); }
.ib-color-wifi { color: var(--ib-color-wifi); }

// to be changed

.ib-color-sessions { color: rgb(205, 246, 204); }
.ib-color-appointments { color: #004687; }
.ib-color-chart { color: #f0ad4e; }
.ib-color-contacts { color: #546a79; }
.ib-color-codes { color: var(--ib-color-brown1); }
.ib-color-admin { color: #2E353D; }
.ib-color-design { color: var(--ib-color-pink); }

.ib-color-page { color: var(--ib-color-navy); }
// .ib-color-email { color: var(--ib-color-light-blue); }
.ib-color-category { color: var(--ib-color-green);  }
.ib-color-subject { color: var(--ib-color-ultramarine); }
.ib-color-template { color: var(--ib-color-baltic); }


.ib-color-home { color: var(--ib-color-home); }
.ib-color-plus { color: var(--ib-color-plus); }
.ib-color-minus { color: var(--ib-color-minus); }
